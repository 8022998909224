<template>
  <div class="my-container">
    <div class="my-wrapper">
      <div class="login-logo"></div>
      <div class="login-button" @click="handleLogin"></div>
    </div>
    <div class="my-footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备2024045068号-1</a>
    </div>
    <div class="my-footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备2024045068号-1</a>
    </div>
    <Login ref="login_model" @login-result="afterLogin"></Login>
  </div>
</template>

<script>
const Login = () => import("@/components/Login")

export default {
  name: "Intro",
  components: {
    Login
  },
  data() {
    return {
      is_loading: false,
      locale: 'zh-cn'
    }
  },
  mounted() {
    let locale = localStorage.getItem("locale")
    if (locale) {
      this.$i18n.locale = locale
      this.locale = locale
    }
  },
  methods: {
    switchLocale: function (lang) {
      this.locale = lang
      this.$i18n.locale = lang
      localStorage.setItem("locale", lang)
    },
    handleLogin: function () {
      let me = this
      const token = localStorage.getItem("token");
      if (!token) {
        me.$refs.login_model.v_login_form = true
      } else {
        this.axios.get("/settings/user").then((res) => {
          localStorage.setItem('user_info', JSON.stringify(res.data))
          me.$router.push("/home/workspace")
        }).catch(() => {
          me.$refs.login_model.v_login_form = true
        })
      }
    },
    showLoading: function () {
      let me = this
      me.is_loading = true
      let progress = 0;
      let interval = setInterval(function () {
        progress += 0.333;
        if (progress > 100) progress = 100
        me.updateProgressBar(progress);
        if (progress >= 100) {
          clearInterval(interval);
          me.$router.push("/home/workspace")
        }
      }, 10);
    },
    updateProgressBar: function (progress) {
      let progressBar = document.getElementById('progressBar');
      progressBar.style.width = progress + '%';
    },
    afterLogin: function (isOk) {
      let me = this
      if (isOk === 'ok') {
        me.$router.push("/home/workspace")
      }
    }
  }
}
</script>

<style scoped>
.my-container {
  width: 100%;
  height: 100%;
  background-image: url("../assets/demo/home_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.my-wrapper {
  margin-left: 50%;
  display: flex;
  flex-direction: column;
}

.login-logo {
  width: 3.38rem;
  height: 1.4rem;
  background: url("../assets/demo/home_adgo.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-button {
  width: 1.83rem;
  height: 0.87rem;
  background: url("../assets/demo/home_btm.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

/deep/ .login-modal .el-dialog .el-dialog__header {
  font-size: 18px;
}


.ft-nm {
  font-weight: bold;
}
.my-footer{
  position: fixed;
  bottom: 0.2rem;
  right: 0.2rem;
  font-size: 0.12rem;
}


</style>
